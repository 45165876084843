import React from 'react'
import PropTypes from 'prop-types'

const PostsPagination = ({ hasNextPage, hasPreviousPage, prevPage, nextPage }) => {
  return (
    <nav className='pagination my-6' role='navigation' aria-label='pagination'>
      <a
        href={hasPreviousPage ? prevPage : null}
        rel='prev'
        className={`pagination-previous${hasPreviousPage ? '' : ' is-disabled'}`}
      >
        <span>👈🏻</span>&nbsp;Previous Page
      </a>

      <a
        href={hasNextPage ? nextPage : null}
        rel='next'
        className={`pagination-next${hasNextPage ? '' : ' is-disabled'}`}
      >
        Next Page&nbsp;<span>👉🏻</span>
      </a>
    </nav>
  )
}

PostsPagination.propTypes = {
  hasNextPage: PropTypes.bool.isRequired,
  hasPreviousPage: PropTypes.bool.isRequired,
  prevPage: PropTypes.string.isRequired,
  nextPage: PropTypes.string.isRequired,
}

export default PostsPagination

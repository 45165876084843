import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import { FormattedMessage } from 'react-intl'
import Layout from 'components/Layout'
import SEO from 'components/Seo'
import RecommendedCard from 'components/RecommendedCard'
import PostsPagination from 'components/PostsPagination'
import { IntlProvider } from 'contexts/intl/IntlContext'
import { StaticImage } from 'gatsby-plugin-image'
import { internalLinkPaths } from 'constants/paths'
import PageTitle from 'components/PageTitle'

export const query = graphql`
  query AllMarkdownRemark($limit: Int!, $skip: Int!, $templateKey: String!, $templatePath: [String]!) {
    site {
      siteMetadata {
        siteUrl
        siteName
      }
    }

    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: $templateKey }, path: { in: $templatePath } } }
      sort: { frontmatter: { publishedAt: DESC } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            url
            path
            topics
            description
          }
          html
        }
      }
    }
  }
`

const BlogList = ({ data, location, pageContext }) => {
  const { currentPage, prefixPath, hasNextPage, hasPreviousPage, itemCount, perPage, totalCount } =
    pageContext
  const previousPageCount = currentPage - 1
  const prevPage =
    hasPreviousPage && previousPageCount > 1 ? `${prefixPath}/${(currentPage - 1).toString()}` : prefixPath
  const nextPage = `${prefixPath}/${(currentPage + 1).toString()}`
  const type = pageContext.type
  const titleType = type + '.title'

  const posts = data.allMarkdownRemark.edges.map(({ node }) => node)
  const postsLocale = posts

  const siteMetadata = data.site.siteMetadata
  return (
    <IntlProvider pathname={location.pathname}>
      {({ locale, formatMessage, getPath }) => (
        <Layout>
          <SEO
            lang={locale}
            isBlogPost={false}
            title={formatMessage({ id: titleType.toString() })}
            description={formatMessage({ id: 'meta.description' })}
            keywords={formatMessage({ id: 'meta.keywords' })}
            url={formatMessage({ id: 'meta.url.blog' })}
          />
          <section className='section'>
            <PageTitle pageTitle={<FormattedMessage id={titleType.toString()} />} />
            <div className='container'>
              <hr />
              <div className='grid is-gap-5 is-col-min-16'>
                {postsLocale.map(post => {
                  let topics = post.frontmatter.topics.map(node => node).slice(0, 3)
                  var onGoToUrl = `${post.frontmatter.url}`
                  if (post.frontmatter.path != `videos-post`) {
                    onGoToUrl += `?utm_source=${siteMetadata.siteName}&utm_medium=website&utm_campaign=recommended`
                  }
                  return (
                    <RecommendedCard
                      key={post.fields.slug}
                      title={post.frontmatter.title}
                      subtitle={post.frontmatter.description.substring(0, 100)}
                      link={onGoToUrl}
                      image={
                        <StaticImage
                          src='../../static/img/mihirpipermitwala-placeholder.jpg'
                          alt={siteMetadata.siteUrl + post.fields.slug}
                          layout='constrained'
                          style={{ borderRadius: '0.8em 0.8em 0em 0em', border: '1em' }}
                          transformOptions={{
                            fit: 'cover',
                            cropFocus: 'attention',
                          }}
                          backgroundColor='#181818'
                        />
                      }
                      htmlContent={post.frontmatter.path == `videos-post` ? post.html : null}
                      topics={topics}
                    />
                  )
                })}
              </div>

              <PostsPagination
                hasNextPage={hasNextPage}
                hasPreviousPage={hasPreviousPage}
                prevPage={prevPage}
                nextPage={nextPage}
              />

              <p className='has-text-centered'>
                <FormattedMessage id='checkOut' />{' '}
                {prefixPath == internalLinkPaths.recommended && (
                  <Link to={getPath('recommendedStories', locale)}>
                    <FormattedMessage id='recommended.stories.title' />
                  </Link>
                )}
                {prefixPath == internalLinkPaths.recommendedStories && (
                  <Link to={getPath('recommendedVideos', locale)}>
                    <FormattedMessage id='recommended.videos.title' />
                  </Link>
                )}
                {prefixPath == internalLinkPaths.recommendedVideos && (
                  <Link to={getPath('recommendedServices', locale)}>
                    <FormattedMessage id='recommended.services.title' />
                  </Link>
                )}
                {prefixPath == internalLinkPaths.recommendedServices && (
                  <Link to={getPath('recommended', locale)}>
                    <FormattedMessage id='recommended.title' />
                  </Link>
                )}
                {'.'}
              </p>
            </div>
          </section>
        </Layout>
      )}
    </IntlProvider>
  )
}

BlogList.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default BlogList

import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { FaYoutube, FaGlobe } from 'react-icons/fa'
import TagsTopicList from '../TagsTopicList'

const RecommendedCard = ({ title, subtitle, link, topics, image, htmlContent }) => {
  return (
    <Link to={htmlContent == null ? link : null}>
      <div className='cell'>
        <div className='card'>
          <div className='card-image'>
            {htmlContent != null ? (
              <div className='image is-2by1' dangerouslySetInnerHTML={{ __html: htmlContent }} />
            ) : (
              <figure className='image is-2by1'>{image}</figure>
            )}
          </div>
          <div className='card-content'>
            <div className='media'>
              <div className='media-left'>
                {htmlContent != null ? (
                  <FaYoutube className='image is-48x48'></FaYoutube>
                ) : (
                  <FaGlobe className='image is-48x48'></FaGlobe>
                )}
              </div>
              <div className='media-content'>
                <p className='title is-4'>{title}</p>
                <p className='subtitle is-6'>{subtitle}</p>
              </div>
            </div>
            <div className='content'>
              {topics.length > 0 && (
                <TagsTopicList topics={topics} />
              )}
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

RecommendedCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  link: PropTypes.string,
  topics: PropTypes.arrayOf(PropTypes.string),
  image: PropTypes.node,
  htmlContent: PropTypes.node,
}

RecommendedCard.defaultProps = {
  link: null,
  topics: [],
  image: null,
}

export default RecommendedCard
